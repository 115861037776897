<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Remark</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8" sm="8" md="6" lg="6">
            <v-textarea
              outlined
              flat
              label="Remark"
              rows="3"
              background-color="white"
              v-model="form.remark"
              :rules="[v => !!v || 'Remark is required']"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
        <v-btn color="error" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-remark",
  props: {
    dialog: Boolean,
    form: Object,
  },
  methods: {
    save() {
      this.$emit("save", this.form);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
