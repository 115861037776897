<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Invoice History Remark</span>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="history" disable-pagination hide-default-footer>
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "history-remark",
  props: {
    dialog: Boolean,
    history: Array,
  },
  data() {
    return {
      headers: [
        {
          id: 1,
          text: "No",
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Date",
          value: "createdAt",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Remark",
          value: "remark",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
