<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            label="Customer"
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            :items="customers"
            item-text="name"
            item-value="id"
            v-model="customerId"
            :rules="[v => !!v || 'Customer is required']"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col cols="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Date"
                outlined
                flat
                dense
                background-color="white"
                v-model="params.date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col> -->
        <v-col cols="2">
          <v-btn @click="search" color="primary" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn color="primary" icon @click="openDialogUpdate(item)" class="mr-2">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn color="primary" icon @click="openDialogHistory(item)" class="mr-2">
            <v-icon>
              mdi-note
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <dialog-remark
      :dialog="dialogUpdate"
      :form="item"
      @save="saveRemark"
      @close="close"
    ></dialog-remark>
    <history-remark :dialog="dialogHistory" :history="history" @close="close"></history-remark>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import DialogRemark from "@/components/DialogRemark";
import HistoryRemark from "@/components/HistoryRemark";

export default {
  name: "update-remark",
  components: {
    DialogRemark,
    HistoryRemark,
  },
  data() {
    return {
      customerId: 0,
      items: [],
      item: {},
      history: [],
      dialogUpdate: false,
      dialogHistory: false,
      headers: [
        {
          id: 1,
          text: "NO",
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Invoice Number",
          value: "invoiceNumber",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Remark",
          value: "remark",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Action",
          value: "action",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  computed: {
    ...mapState("external", ["customers"]),
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("salePayment/getInvoiceForRemark", this.customerId)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([await this.$store.dispatch("external/getCustomers")])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.dialogUpdate = false;
      this.dialogHistory = false;
    },
    openDialogUpdate(item) {
      item.remark = "";
      this.item = item;
      this.dialogUpdate = true;
    },
    async openDialogHistory(item) {
      this.$store.commit("SET_LOADER", true);
      if (item.type === "DP") {
        await this.$store
          .dispatch("saleDownPayment/getHistoryRemark", item.transactionId)
          .then(response => {
            this.history = response.data;
            this.dialogHistory = true;
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        await this.$store
          .dispatch("saleInvoice/getHistoryRemark", item.transactionId)
          .then(response => {
            this.history = response.data;
            this.dialogHistory = true;
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    async saveRemark(item) {
      this.$store.commit("SET_LOADER", true);
      if (item.type === "DP") {
        await this.$store
          .dispatch("saleDownPayment/updateRemark", item)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        await this.$store
          .dispatch("saleInvoice/updateRemark", item)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
